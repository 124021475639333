export function accordion() {
  const title = document.querySelectorAll('.js-accordion-title');

  function toggle(){
    const content = this.nextElementSibling;
    this.classList.toggle('is-active');
    content.classList.toggle('is-open');
  }

  for (let i = 0; i < title.length; i ++){
    title[i].addEventListener('click', toggle)
  }

}