import '../scss/styles.scss';

import {accordion} from "./modules/accordion";
import {drawerToggle} from "./modules/drawerToggle";
import {headerColorChange} from "./modules/headerColorChange";
import {initSlider} from "./modules/slider";
import {initModal} from "./modules/initBaguettebox";
import {categoryFilter} from "./modules/categoryFilter";

window.addEventListener('load', () => {
  accordion();
  drawerToggle();
  headerColorChange();
  initSlider();
  initModal();
  categoryFilter();

  //
  // Array.from(document.querySelectorAll('.js-cat-filter-btn')).forEach((btn) => {
  //   const target = btn.getAttribute('data-slug')
  //
  //   btn.addEventListener('click', (e) => {
  //     e.preventDefault()
  //     Array.from(document.querySelectorAll('.js-cat-filter-btn')).forEach((btn2) => {
  //       btn2.classList.remove('is-active')
  //     })
  //     btn.classList.add('is-active')
  //
  //     Array.from(document.querySelectorAll('.works-list__item')).forEach((item) => {
  //       const slugs = item.getAttribute('data-slug').split(',')
  //       let i = 0;
  //
  //       if (target === 'all') {
  //         item.style.display = 'block'
  //         return
  //       }
  //
  //       slugs.forEach((slug)=>{
  //         i += target === slug ? 1 : 0
  //       })
  //
  //       item.style.display = i > 0 ? 'block' : 'none'
  //     })
  //   })
  // })

})


window.addEventListener('scroll', (e) => {
  e.preventDefault()
  headerColorChange();
})