export function categoryFilter() {
  const parm = getQueryString();
  const queryTarget = parm !== null ? String(parm['t']) : '';

  if (queryTarget) {
    Array.from(document.querySelectorAll('.js-cat-filter-btn')).forEach((btn) => {
      btnClassChange(btn, queryTarget)
    })

    Array.from(document.querySelectorAll('.works-list__item')).forEach((item) => {
      itemClassChange(item, queryTarget)
    })
  }

  Array.from(document.querySelectorAll('.js-cat-filter-btn')).forEach((btn) => {
    const target = btn.getAttribute('data-slug')

    btn.addEventListener('click', (e) => {
      e.preventDefault()

      Array.from(document.querySelectorAll('.js-cat-filter-btn')).forEach((btn2) => {
        btnClassChange(btn2, target)
      })

      Array.from(document.querySelectorAll('.works-list__item')).forEach((item) => {
        itemClassChange(item, target)
      })
    })
  })
}

/**
 * URLパラメーターをオブジェクトとして取得処理
 * @return {Object|null}
 */
const getQueryString = () => {
  if (1 < document.location.search.length) {
    var query = document.location.search.substring(1);
    var parameters = query.split('&');

    var result = new Object();
    for (var i = 0; i < parameters.length; i++) {
      var element = parameters[i].split('=');

      var paramName = decodeURIComponent(element[0]);
      var paramValue = decodeURIComponent(element[1]);

      result[paramName] = decodeURIComponent(paramValue);
    }
    return result;
  }
  return null;
}

/**
 * フィルターボタン アクティブクラス切替処理
 * @param btn {HTMLElement}
 * @param target {String}
 */
const btnClassChange = (btn, target) => {
  if (target === btn.getAttribute('data-slug')) {
    btn.classList.add('is-active')
  } else {
    btn.classList.remove('is-active')
  }
}
/**
 * リストアイテム 表示切替処理
 * @param item {HTMLElement}
 * @param target {String}
 */
const itemClassChange = (item, target) => {
  const slugs = item.getAttribute('data-slug').split(',')
  let i = 0;

  if (target === 'all') {
    item.style.display = 'block'
    return
  }

  slugs.forEach((slug)=>{
    i += target === slug ? 1 : 0
  })
  item.style.display = i > 0 ? 'block' : 'none'
}