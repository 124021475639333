import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export function initSlider() {
  if (document.querySelector('.mv-slider')) {
    const mvSliderOption = {
      loop: true,
      speed: 2000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.mv-slider-pagination',
        type: 'bullets',
        clickable: true,
      }
    }

    const swiper = new Swiper('.mv-slider', mvSliderOption); // eslint-disable-line
  }

  if (document.querySelector('.single-kv-slider')) {
    const singleKvSliderOption = {
      loop: true,
      speed: 2000,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    }

    const swiper = new Swiper('.single-kv-slider', singleKvSliderOption); // eslint-disable-line
  }
}